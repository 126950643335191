import { NAVIGATION_HEIGHT_CLASSNAME } from 'components/PublicNavigation/PublicNavigation';
import dynamic from 'next/dynamic';
import { truckMapConfig } from 'truckMapConfig';

// Avoids UI shift
export const NavigationLoadingFallback = () => <div className={NAVIGATION_HEIGHT_CLASSNAME} />;

export const PublicNavigationDynamic = dynamic(
  () =>
    !truckMapConfig.isTenstreet
      ? import('components/PublicNavigation').then((mod) => mod.PublicNavigation)
      : Promise.resolve(() => null),
  { ssr: false, loading: NavigationLoadingFallback }
);
