'use client';
import { ClientSideDeviceSecret } from 'components/DeviceSecret/ClientSideDeviceSecret';
import { ClientPageProvider } from 'components/Page/ClientPageProvider';
import { PublicNavigationDynamic } from 'components/PublicNavigation/PublicNavigationDynamic';
import { TruckOptionsProvider } from 'hooks/useTruckOptions/TruckOptionsProvider';
import dynamic from 'next/dynamic';
import { RoutePlannerPageProps } from 'types/pages/RoutePlannerTypes';

const RoutePlannerPageContent = dynamic(
  () => import('./RoutePlannerPageContent').then((mod) => mod.RoutePlannerPageContent),
  { ssr: false }
);

export default function RoutePlannerPage({
  contentfulQuery,
  truckOptions,
  tripData
}: RoutePlannerPageProps) {
  return (
    <div className="w-screen h-screen overflow-hidden">
      <ClientPageProvider contentfulQuery={contentfulQuery}>
        <TruckOptionsProvider value={truckOptions}>
          <PublicNavigationDynamic />
          <RoutePlannerPageContent tripData={tripData} />
          <ClientSideDeviceSecret />
        </TruckOptionsProvider>
      </ClientPageProvider>
    </div>
  );
}
