'use client';
import { ITruckOptionsPayload } from '@truckmap/common';
import { TruckOptionsContext } from 'hooks/useTruckOptions/useTruckOptions';

type TruckOptionsProviderProps = {
  value: ITruckOptionsPayload;
  children: React.ReactNode;
};

export const TruckOptionsProvider: React.ComponentType<TruckOptionsProviderProps> = ({
  value,
  children
}) => {
  return <TruckOptionsContext.Provider value={value}>{children}</TruckOptionsContext.Provider>;
};
